import { Accounts, AuthResponse, GetJWTResponse } from './modules'
import { GigyaError, UserData } from '../'
import { isArray, isObject } from 'lodash'

import { GigyaStorage } from './storage'

export interface ValidationError {
  errorCode: number
  fieldName: string
  message: string
}

export interface Request {
  // eslint-disable-next-line camelcase
  login_token?: string
  regToken?: string
  context?: string;
  include?: string;
}

export interface Response {
  apiVersion: number
  callId: string
  statusCode: number
  statusReason: string
  time: string
  errorCode: number
  errorMessage?: string
  errorDetails?: string
  validationErrors?: Array<ValidationError>
  isAvailable?: boolean
}

export class Gigya {
  private storage: GigyaStorage
  public readonly accounts: Accounts

  constructor (private dataCenter: string, private apiKey: string) {
    this.storage = new GigyaStorage()
    this.accounts = new Accounts(this)
  }

  public async getUser (): Promise<UserData | null> {
    return this.storage.get('user') || null
  }

  public async setUser (data: UserData | null): Promise<UserData | null> {
    if (data) {
      this.storage.set('user', data)
      const jwtResponse = await this.accounts.getJWT({ UID: data.UID })
      this.setJWTStorage(jwtResponse)
    } else {
      this.storage.set('user', null)
      this.storage.set('lToken', null)
      this.storage.set('jwt', null)
    }
    return data
  }

  public setJWTStorage (data: GetJWTResponse): void {
    this.storage.set('jwt', data.id_token)
  }

  private buildRequestBody<R extends Request> (params: R): URLSearchParams {
    const body = new URLSearchParams({
      apiKey: this.apiKey,
      authMode: 'cookie',
      format: 'json'
    })

    for (const key in params) {
      if (isObject(params[key]) || isArray(params[key])) {
        body.set(key, JSON.stringify(params[key]))
      } else {
        body.set(key, params[key] as never as string)
      }
    }

    const lToken = this.storage.get('lToken')
    if (lToken) {
      body.set('login_token', lToken)
    }

    const rToken = this.storage.get('rToken')
    if (rToken) {
      body.set('regToken', rToken)
      this.storage.unset('rToken')
    }

    return body
  }

  private buildApiUrl (endpoint: string, queryParams: string | null = null) {
    const namespace = endpoint.substring(0, endpoint.indexOf('.'))

    return `https://${namespace}.${this.dataCenter}.gigya.com/${endpoint}` + (queryParams ? `?${queryParams}` : '')
  }

  private checkAuthResponse (response: Partial<AuthResponse>, endpoint: string): void {
    if ('sessionInfo' in response && response.sessionInfo?.cookieValue) {
      this.storage.set('lToken', response.sessionInfo.cookieValue)
    }

    if ('regToken' in response && response.regToken) {
      this.storage.set('rToken', response.regToken)
    }

    if (endpoint === 'accounts.logout') {
      this.storage.clear()
    }
  }

  private static get requestHeaders () {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  public request<R> (method: string, endpoint: string, params: Request): Promise<R> {
    if (!this.dataCenter.length || !this.apiKey.length) {
      throw new Error('Datacenter and API key is required for GIGYA!')
    }

    const hasBody = method !== 'GET'
    const body = this.buildRequestBody(params)
    const url = this.buildApiUrl(endpoint, !hasBody ? body.toString() : null)

    return new Promise<R>((resolve, reject) => {
      fetch(url, {
        method: method,
        headers: Gigya.requestHeaders,
        body: hasBody ? body : null,
        credentials: 'include'
      }).then(response => response.json())
        .then((result: R & Response) => {
          if (result.statusCode >= 200 && result.statusCode < 300) {
            this.checkAuthResponse(result, endpoint)
            resolve(result)
          } else {
            reject(new GigyaError(
              result.statusCode,
              result.statusReason,
              result.errorCode,
              result.errorDetails,
              result.errorMessage,
              result.validationErrors
            ))
          }
        })
        .catch(error => reject(error))
    })
  }

  public post<R> (endpoint: string, params: Request = {}): Promise<R> {
    return this.request<R>('POST', endpoint, params)
  }

  public get<R> (endpoint: string, params: Request = {}): Promise<R> {
    return this.request<R>('GET', endpoint, params)
  }

  public buildSocialLogin (endpoint: string, params: Request = {}): String {
    const body = this.buildRequestBody(params)
    const url = this.buildApiUrl(endpoint, body.toString())
    return url;
  }
}
