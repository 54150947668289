
import { ContentData, ListDefaultContentsContentTypesEnum, MinimalContentData } from '@rtl/api'
import { mixins, Options, Prop } from 'vue-property-decorator'

import { ContentListDecoratedPayload, ContentListPayload } from '../../store'
import TopNewsWidgetBlock from '../components/widget/TopNewsWidgetBlock.vue'
import { FetchContentListMixin, GetPageMixin } from '../mixins'
import WidgetMixin, { WidgetMeta, widgetMetaFields } from './WidgetMixin'

export const meta: WidgetMeta = {
  label: 'Top news',
  groups: {
    style: 'Beállítás',
    data: 'Adatok'
  },
  defaultGroup: 'style',
  fields: {
    ...widgetMetaFields,
    secondColLength: {
      label: 'A) Darabszám',
      type: Number,
      default: 4,
      props: {
        min: 1
      }
    },
    thirdColLength: {
      label: 'B) Darabszám',
      type: Number,
      default: 2,
      props: {
        min: 1
      }
    },
    payload: {
      group: 'data',
      type: 'payload',
      default: {
        contentTypes: [ListDefaultContentsContentTypesEnum.News]
      }
    },
    backgroundUrl: {
      type: String,
      props: {
        placeholder: 'Háttérkép url'
      }
    },
    bannerZone: {
      type: String,
      props: {
        placeholder: 'Banner zóna név'
      },
      default: 'widget_1'
    }
  }
}

@Options({
  name: 'TopNewsWidget',
  components: {
    TopNewsWidgetBlock
  }
})
export default class TopNewsWidget extends mixins(GetPageMixin, WidgetMixin, FetchContentListMixin) {
  @Prop({
    type: Number,
    default: 4
  })
  readonly secondColLength!: number

  @Prop({
    type: Number,
    default: 2
  })
  readonly thirdColLength!: number

  @Prop({
    type: String,
    default: ''
  })
  readonly backgroundUrl!: string

  @Prop({
    type: String,
    default: ''
  })
  readonly bannerZone!: string

  @Prop({
    type: Object,
    default: () => ({})
  })
  readonly payload!: Partial<ContentListDecoratedPayload>

  get items (): Array<ContentData | MinimalContentData> {
    return this.contentList?.items || []
  }

  get bigContent (): ContentData | MinimalContentData | null {
    return this.items[0] || null
  }

  get secondColContents (): Array<ContentData | MinimalContentData> {
    return this.items.slice(1, this.secondColLength + 1) || []
  }

  get thirdColContents (): Array<ContentData | MinimalContentData> {
    return this.items.slice(this.secondColLength + 1, this.secondColLength + this.thirdColLength + 1) || []
  }

  get contentListPayload (): ContentListPayload {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { decorators, ...payload } = this.payload

    return {
      folderIds: !payload?.tags && this.page?.site?.folderId ? [this.page?.site?.folderId] : undefined,
      contentTypes: [ListDefaultContentsContentTypesEnum.News],
      limit: 1 + this.secondColLength + this.thirdColLength,
      ...payload
    }
  }
}
