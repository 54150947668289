
import { Inject, Options, Prop, Vue, Watch } from 'vue-property-decorator'

import { AdStore, getMedia, MediaEnum } from '../../utils'
import Banner from '../components/Banner.vue'
import { WidgetMeta } from './WidgetMixin'

declare global {
  interface Window {
    ado?: unknown
    [key: string]: any
  }
}

export const meta: WidgetMeta = {
  label: 'Banner',
  fields: {
    zone: {
      type: String,
      props: {
        required: true,
        placeholder: 'Zóna azonosító'
      }
    }
  },
  timing: false
}

@Options({
  components: {
    Banner
  }
})
export default class BannerWidget extends Vue {
  @Inject({ from: 'adStore' })
  readonly adStore!: AdStore

  @Prop({
    type: [String, Object],
    default: () => ({})
  })
  readonly zone!: string | Record<string, string | null>

  @Prop({
    type: Boolean,
    default: true
  })
  readonly showBackground!: boolean

  @Prop({
    type: String,
    default: 'bottom'
  })
  readonly labelPosition!: string

  @Prop({
    type: Boolean,
    default: true
  })
  readonly lazy!: boolean

  adService: any | null = null // eslint-disable-line @typescript-eslint/no-explicit-any
  media: MediaEnum | null = null
  window: Window | null = null
  viewportObserver: IntersectionObserver | null = null
  inViewport = false

  get mediaPrefix () {
    switch (this.media) {
      case MediaEnum.APP:
        return 'app_'
      case MediaEnum.MOBILE:
        return 'm_'
      case MediaEnum.TABLET:
        return 't_'
    }
    return ''
  }

  get zoneKey () {
    if (!this.media) {
      return ''
    }
    if (typeof this.zone === 'string') {
      return `${this.mediaPrefix}${this.zone}`
    }
    return this.zone[this.media]
  }

  get adConfig () {
    return this.adStore?.adoKeys
  }

  get adGroup () {
    if (this.media && !this.adStore?.resetting) {
      return this.adConfig?.[this.media]
    } else {
      return null
    }
  }

  get masterId () {
    return this.adGroup?.masterId
  }

  get slaveId () {
    if (this.zoneKey) {
      return this.adGroup?.zones?.[this.zoneKey]
    } else {
      return null
    }
  }

  get masterConfig () {
    if (this.masterId && this.adGroup) {
      return {
        id: this.masterId,
        vars: {
          ...this.adGroup.vars,
          datame: this.window?.advCC
        },
        keys: this.adGroup.keys,
        server: 'gemhu.adocean.pl'
      }
    }
    return null
  }

  @Watch('masterConfig')
  initMaster () {
    if (this.adService && this.masterConfig && this.adStore && !this.adStore.master) {
      this.adStore.master = this.masterId
      this.adService.master(this.masterConfig)
    }
  }

  @Watch('slaveId')
  @Watch('inViewport')
  initSlave () {
    if ((!this.lazy || this.inViewport) && this.adService && this.slaveId && this.masterId && this.adStore && !this.adStore.slaves.includes(this.slaveId)) {
      this.initMaster()
      this.adStore.slaves.push(this.slaveId)

      this.$nextTick(() => {
        this.adService.slave(this.slaveId, { myMaster: this.masterId })
        this.measure()
      })
    }
  }

  @Watch('adService')
  initAd () {
    if (this.adStore) {
      if (!this.adStore.initialized) {
        this.adService?.config({
          mode: 'new',
          xml: false,
          consent: true,
          characterEncoding: true,
          attachReferrer: true,
          fpc: 'auto',
          defaultServer: 'gemhu.adocean.pl',
          cookieDomain: 'SLD'
        })
        this.adService?.preview({ enabled: true })
        this.adStore.initialized = true
      }

      this.initMaster()
      this.initSlave()
    }
  }

  @Watch('$route.fullPath')
  resetAll () {
    if (!this.adStore?.resetting) {
      if (this.adStore.master) {
        this.adStore.master = null
      }
      if (this.adStore.slaves.length) {
        this.adStore.slaves.forEach(slaveId => {
          if (this.window?.[slaveId]) {
            this.window[slaveId] = null
          }
        })
        this.adStore.slaves = []
      }

      const googletag = this.window?.googletag
      if (googletag && googletag.apiReady) {
        googletag.destroySlots() // Reset Google Ads slots
      }


      this.adStore.resetting = true // this resetting the dom elements, see v-if in template
      this.$nextTick(() => {
        this.adStore.resetting = false
      })
    }
  }

  measure () {
    if (this.$gtm && this.$gtm.enabled()) {
      this.$gtm.trackEvent({
        event: 'interaction',
        event_name: 'ad_impression',
        ad_format: this.zoneKey
      })
    }
  }

  onResize () {
    this.media = this.window ? getMedia(this.window) : null
  }

  mounted () {
    this.$nextTick(() => {
      const win = this.$root?.$el.ownerDocument.defaultView
      this.window = win

      if (this.lazy) {
        this.viewportObserver = new IntersectionObserver((entries) => {
          this.inViewport = entries.some(entry => entry.isIntersecting)
        })
        this.viewportObserver?.observe(this.$el)
      }

      if (this.window?.ado) {
        this.adService = this.window?.ado
      } else {
        const serviceScript = this.window?.document.querySelector('script[vmid="adocean"]')
        if (serviceScript) {
          serviceScript.addEventListener('load', () => {
            this.adService = this.window?.ado || null
          }, { once: true })
        }
      }

      if (!this.adStore?.media) {
        this.onResize()
        this.window?.addEventListener('resize', this.onResize)
      } else {
        this.media = this.adStore.media
      }
    })
  }

  beforeUnmount () {
    if (!this.adStore?.media) {
      this.window?.removeEventListener('resize', this.onResize)
    }
    this.viewportObserver?.disconnect()
  }
}
